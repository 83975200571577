import React from 'react';
import Layout from '../components/layout';
import PaddedSection from '../components/PaddedSection';

const AboutPage = () => (
  <Layout>
    <PaddedSection>
      <br />
      <br />
      <div>
        <h1>About Us</h1>
        <p>
          Pixo Pixa started out as a birthday gift for my girlfriend and now has transformed into a company like no other.
          My girlfriend was always giving me handmade comic books that told the story of the adventures we'd gotten into
          over the past year. They were amazing, funny, thoughtful and I couldn't compete. After three years of getting
          my butt kicked when it came to birthdays, I came up with PixoPixa. I made a platformer video game starring my girlfriend.
          I made custom game text between each level, to tell a fun and personalized story, and the idea for PixoPixa was born.
          The first PixoPixa game was too hard, and still partially a failure, but served as proof of concept. As the years
          went by I honed my personalized video game making skills (as one does), until PixoPixa became what it is today.
          Our company is about giving a gift with a personalized touch, it's about connecting, telling a story and most of
          all having fun. We aren't a video game company that makes persoanlized gifts, we are a personalized gift company that
          makes video games.
        </p>
        <h2>About Me</h2>
        <p>
          I'm a Canadian, living in Turkey. I first came to Ankara Turkey on a student exchange, met my girlfriend there and
          decided not to go back home. I studied Physics and Mathematics in university and have always enjoyed mathematical logic based
          problem solving. It was about a year after meeting my girlfriend that I learned she takes birthdays seriously, like really seriously,
          like they are a big deal. She'd spend a month working tirelessly to make me my wonderful birthday gifts, so I realized I needed to
          step it up. After I finished university in ODTU in Turkey, we worked in Egypt for the summer, then I started teaching English
          as a Second Language in Ankara Turkey. In the summer of 2016 Ankara Turkey got just a little too colorful, so we decided it was
          time for a change of scenery. We moved to Taiwan, and we continued teaching ESL. To describe my teaching skills in a single word? "Bad"
          in two words? "Very Bad". Teaching was not for me, by the time I finished my last day of teaching my students had gone method in portraying
          lord of the flies (not that they could read it). I needed a contingency plan, bad. That's when I started learning coding, and when
          I realized I could make my girlfriend a video game birthday gift, and when PixoPixa first reached a drawing board. I've since reached
          my goal of becoming a computer programmer. I have been working on PixoPixa in my spare time, but decided now it's time to take it seriously
          We want to share the ability to give a personalized gift to everyone, without the need for tens of hours of work. If our company
          can put a few smiles on a few faces we'll consider that a success.
          {' '}
        </p>
        <h2>About Pixo Pixa</h2>
        <p>
          Pixo Pixa is a multi-national company employing thousands and donating millions of profits each year to end
          poverty and fight global environmental destruction ... we wish. Acctually we are a small start up in our early
          stages, if you have a question or comment you will recieve a response from the owner of the company. We are excited
          to take our first steps as a company and so excited that you are here on this adventure with us, thank you for your interst and support!
        </p>
        <p> The Pixo Pixa Team</p>
      </div>
      <br />
      <br />
    </PaddedSection>
  </Layout>
);

export default AboutPage;
